var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("StudentSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm.$_userMixins_isSchoolUser && !_vm.$store.state.forceStudentView
            ? _c("StudentHeaderPortlet", { attrs: { "display-wide": true } })
            : _vm._e(),
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                "no-center": "",
                opacity: "1",
                blur: "10px",
                show: _vm.encryptionEnabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center pt-4 kt-font-lg" },
                        [
                          _vm._v(
                            " PII is currently encrypted. Please unlock PII to view this page. "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center pt-4" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-brand btn-bold btn-upper mt-4",
                                staticStyle: { "min-width": "160px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrypt()
                                  },
                                },
                              },
                              [_vm._v(" Unlock PII ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "kt-portlet kt-portlet--height-fluid",
                          },
                          [
                            _c("div", { staticClass: "kt-portlet__body" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section kt-section--first" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__body" },
                                    [_c("PortletLoader")],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm.noData
                    ? _c("div", { staticClass: "col-12" }, [
                        !_vm.encryptionEnabled
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "kt-portlet kt-portlet--height-fluid",
                              },
                              [
                                _c("div", { staticClass: "kt-section__body" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-outline-info m-0",
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-icon" },
                                            [
                                              _c("i", {
                                                staticClass: "flaticon-warning",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " Add a new guardian or upload a RCON from ATS to populate this section for all students "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-upper",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.addGuardian()
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus",
                                              }),
                                              _vm._v(" Add Guardian "),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _vm.canEdit
                              ? _c("div", { staticClass: "kt-portlet" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-portlet__body p-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget kt-widget--user-profile-3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "kt-widget__top" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget__content pl-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__head",
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "kt-widget__title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Student Guardians "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget__action",
                                                        },
                                                        [
                                                          _vm.isSchoolAdmin
                                                            ? _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "kt-checkbox kt-checkbox--tick kt-checkbox--danger mr-3",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.showDeletes,
                                                                          expression:
                                                                            "showDeletes",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm.showDeletes
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm.showDeletes,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : _vm.showDeletes,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm.showDeletes,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                (_vm.showDeletes =
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  ))
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                (_vm.showDeletes =
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    ))
                                                                            }
                                                                          } else {
                                                                            _vm.showDeletes =
                                                                              $$c
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    " Show deletions and No Access "
                                                                  ),
                                                                  _c("span"),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-brand btn-sm btn-upper ml-3",
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  !_vm.canEdit,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    return _vm.addGuardian()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-plus",
                                                              }),
                                                              _vm._v(
                                                                " Add Guardian "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._l(_vm.chunks, function (guardians, idx) {
                              return _c(
                                "div",
                                { key: `chunk${idx}`, staticClass: "row" },
                                _vm._l(guardians, function (guardian, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: `guardian_${guardian.studentGuardianId}_${index}`,
                                      staticClass: "col-xl-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-portlet kt-portlet--height-fluid",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-portlet__head kt-portlet__head--noborder",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-portlet__head-label",
                                                },
                                                [
                                                  _c("h3", {
                                                    staticClass:
                                                      "kt-portlet__head-title",
                                                  }),
                                                ]
                                              ),
                                              _vm.canEdit
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-portlet__head-toolbar",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover",
                                                              value: `Show Changes`,
                                                              expression:
                                                                "`Show Changes`",
                                                              modifiers: {
                                                                hover: true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-icon",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "-20px",
                                                          },
                                                          attrs: {
                                                            href: "#",
                                                            "data-toggle":
                                                              "dropdown",
                                                            "aria-expanded":
                                                              "false",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.changeLogs(
                                                                guardian.studentGuardianId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "la la-clock-o kt-font-brand",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "kt-portlet__body" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget kt-widget--user-profile-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__head",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget__info pl-0 w-100",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "kt-widget__username",
                                                              class: {
                                                                "kt-font-danger deleted":
                                                                  guardian.deleted,
                                                              },
                                                            },
                                                            [
                                                              guardian.guardianTitle
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          guardian.guardianTitle
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    guardian.guardianFirstName
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    guardian.guardianLastName
                                                                  )
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          guardian.deleted
                                                            ? [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "kt-widget__desc",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "kt-badge kt-badge--danger kt-badge--inline kt-badge--lg kt-badge--rounded",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "DELETED"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : [
                                                                guardian.oralLanguage ||
                                                                guardian.writtenLanguage ||
                                                                guardian.relationToStudent
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "kt-widget__desc",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              "kt-font-" +
                                                                              _vm.classForGuardian(
                                                                                guardian
                                                                              ),
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  guardian.relationToStudent
                                                                                )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        (guardian.oralLanguage ||
                                                                          guardian.writtenLanguage) !==
                                                                        "English"
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "b-tooltip",
                                                                                      rawName:
                                                                                        "v-b-tooltip.hover",
                                                                                      value: `Oral lang: ${
                                                                                        guardian.oralLanguage ||
                                                                                        "N/A"
                                                                                      } Written lang: ${
                                                                                        guardian.writtenLanguage ||
                                                                                        "N/A"
                                                                                      }`,
                                                                                      expression:
                                                                                        "`Oral lang: ${guardian.oralLanguage || 'N/A'} Written lang: ${guardian.writtenLanguage || 'N/A'}`",
                                                                                      modifiers:
                                                                                        {
                                                                                          hover: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--unified-dark",
                                                                                class:
                                                                                  {
                                                                                    "pull-right":
                                                                                      guardian.relationToStudent,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      guardian.oralLanguage ||
                                                                                        guardian.writtenLanguage ||
                                                                                        "N/A"
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__body",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget__section",
                                                          staticStyle: {
                                                            height: "80px",
                                                          },
                                                        },
                                                        [
                                                          guardian.residesWithStudent
                                                            ? _c("i", {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover",
                                                                    value: `Resides with student`,
                                                                    expression:
                                                                      "`Resides with student`",
                                                                    modifiers: {
                                                                      hover: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "flaticon flaticon-home kt-font-success",
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                guardian.guardianAuthorization
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget__item",
                                                        },
                                                        [
                                                          _vm._l(
                                                            guardian.emailAddresses.filter(
                                                              (e) =>
                                                                !e.deleted ||
                                                                _vm.showDeletes
                                                            ),
                                                            function (
                                                              email,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: `email_${email.emailAddress}_${idx}`,
                                                                  staticClass:
                                                                    "kt-widget__contact",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: {
                                                                          "kt-font-danger":
                                                                            email.deleted,
                                                                          "kt-widget__label":
                                                                            !email.deleted,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Email:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        class: {
                                                                          "kt-font-danger":
                                                                            email.deleted,
                                                                          "kt-widget__data":
                                                                            !email.deleted,
                                                                        },
                                                                        attrs: {
                                                                          href: "#",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              return _vm.copyTextToClipboard(
                                                                                email.emailAddress
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            email.emailAddress
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._l(
                                                            guardian.phones.filter(
                                                              (p) =>
                                                                !p.deleted ||
                                                                _vm.showDeletes
                                                            ),
                                                            function (phone) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: phone.phoneNumber,
                                                                  staticClass:
                                                                    "kt-widget__contact",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: {
                                                                          "kt-font-danger":
                                                                            phone.deleted,
                                                                          "kt-widget__label":
                                                                            !phone.deleted,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            phone.phoneType
                                                                          ) +
                                                                            ":"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        class: {
                                                                          "kt-font-danger":
                                                                            phone.deleted,
                                                                          "kt-widget__data":
                                                                            !phone.deleted,
                                                                        },
                                                                        attrs: {
                                                                          href: "#",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              return _vm.copyTextToClipboard(
                                                                                phone.phoneNumber
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatPhone(
                                                                              phone.phoneNumber
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.canEdit
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-widget__footer",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-label-primary btn-lg btn-upper",
                                                              class:
                                                                "btn-label-" +
                                                                _vm.classForGuardian(
                                                                  guardian
                                                                ),
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editGuardian(
                                                                      guardian
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    guardian.deleted
                                                                      ? "Restore Guardian"
                                                                      : "Edit Guardian"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _vm.lookup && _vm.student
        ? _c("StudentGuardianModal", {
            ref: "guardian-modal",
            attrs: {
              lookup: _vm.lookup,
              "edit-callback": _vm.editCallback,
              title: _vm.student.firstName
                ? `Guardian for ${_vm.student.firstName} ${_vm.student.lastName}`
                : `Guardian for ${_vm.student.maskedStudentName}`,
              "student-enrollment-id": _vm.studentEnrollmentId * 1,
            },
          })
        : _vm._e(),
      _vm.lookup && _vm.student
        ? _c("StudentGuardianChangeLogModal", {
            ref: "guardian-change-log-modal",
            attrs: {
              lookup: _vm.lookup,
              "edit-callback": _vm.editCallback,
              title: _vm.student.firstName
                ? `Change history for guardian of ${_vm.student.firstName} ${_vm.student.lastName}`
                : `Change history for guardian of ${_vm.student.maskedStudentName}`,
              "student-enrollment-id": _vm.studentEnrollmentId * 1,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }