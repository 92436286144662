import { mapState } from 'vuex';
import deepcopy from 'deepcopy';
import moment from 'moment';
import PortletLoader from '../../components/PortletLoader.vue';
import { decryptObject } from '../../../../shared/encryption.mjs';
import * as network from '../../network';

export default {
    name: 'StudentGuardianChangeLogModal',
    data() {
        return {
            loading: true,
            saving: false,
            dirty: false,
            error: null,
            phoneTypes: ['Mobile', 'Home', 'Work'],
            titles: ['Mr.', 'Mrs.', 'Ms.', 'Dr.', null],
            changes: [],
            guardians: [],
            filteredStudentGuardianId: 0,
            paginatorIndex: 1,
        };
    },
    components: { PortletLoader },
    props: {
        lookup: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        studentEnrollmentId: {
            type: Number,
            required: true,
        },
        editCallback: {
            type: Function,
            required: true,
        },
    },
    computed: mapState({
        user: (state) => state.user,
        relationCodes() {
            const v = this;
            const relationCodes = v.lookup.relationCodes.map((r) => {
                const relation = r;
                relation.selected = v.guardian.relationToStudent == relation.value;
                relation.text = relation.value;
                return relation;
            });
            relationCodes.unshift({
                code: '',
                value: null,
                text: '',
                selected: v.guardian.relationToStudent == null,
            });
            return relationCodes;
        },
        authorizationCodes() {
            const v = this;
            const authorizationCodes = v.lookup.authCodes.map((a) => {
                const authCode = a;
                authCode.selected = v.guardian.guardianAuthorization == authCode.value;
                authCode.text = authCode.value;
                return authCode;
            });
            return authorizationCodes;
        },
        oralLanguages() {
            const v = this;
            const homeLanguages = deepcopy(v.lookup.homeLanguages);
            const languages = homeLanguages.map((l) => {
                const language = l;
                language.selected = Boolean(v.guardian.oralLanguage == language.value);
                language.text = language.value;
                return language;
            });
            languages.sort((a, b) => {
                if (a.value == 'English' || b.value == 'English') return 0;
                if (a.value < b.value) return -1;
                if (a.value > b.value) return 1;
                return 0;
            });
            languages.sort((a, b) => {
                if (a.value === b.value) return 0;
                return a.value < b.value ? -1 : 1;
            });

            // push english to top
            const i = languages.findIndex((o) => o.value === 'English');
            if (i > 0) {
                const eng = languages.splice(i, 1);
                languages.unshift(eng[0]);
            }

            languages.unshift({
                code: '',
                value: null,
                text: '',
                selected: v.guardian.oralLanguage == null,
            });
            return languages;
        },
        writtenLanguages() {
            const v = this;
            const homeLanguages = deepcopy(v.lookup.homeLanguages);
            const languages = homeLanguages.map((l) => {
                const language = l;
                language.selected = v.guardian.writtenLanguage == language.value;
                language.text = language.value;
                return language;
            });

            languages.sort((a, b) => {
                if (a.value === b.value) return 0;
                return a.value < b.value ? -1 : 1;
            });

            // push english to top
            const i = languages.findIndex((o) => o.value === 'English');
            if (i > 0) {
                const eng = languages.splice(i, 1);
                languages.unshift(eng[0]);
            }

            languages.unshift({
                code: '',
                value: null,
                text: '',
                selected: v.guardian.writtenLanguage == null,
            });
            return languages;
        },
        filteredChangeIndex() {
            return this.paginatorIndex - 1;
        },
        filteredChanges() {
            const v = this;
            const idx = v.filteredStudentGuardianId.toString();
            return v.changes.filter((c) => c.studentGuardianId == idx || idx == '0');
        },
        changeDateList() {
            const v = this;
            const unique = new Map();
            v.filteredChanges.forEach((c) => {
                unique.set(c.dateChanged, c);
            });
            return [...unique.values()].map((c) => ({
                studentGuardianChangeLogId: c.studentGuardianChangeLogId,
                dateChanged: c.dateChanged,
                date: moment(c.dateChanged).format('ddd MMM, Do h:mm A'),
                changeType: c.changeType,
            }));
        },
        guardian() {
            const v = this;
            if (!v.currentChange) return null;
            return (v.currentChange.studentGuardian || v.currentChange.previousStudentGuardian);
        },
        selectedStudentGuardian() {
            const v = this;
            return v.guardians.find((g) => g.studentGuardianId == v.filteredStudentGuardianId);
        },
        guardianPhones() {
            const v = this;
            const change = v.currentChange;
            const output = [];
            if (!change) return output;

            const previousPhones = change.previousStudentGuardian ? change.previousStudentGuardian.phones : [];
            const newPhones = change.studentGuardian ? change.studentGuardian.phones : [];
            newPhones.forEach((p) => {
                const incomingPhone = p;
                const existingPhone = previousPhones.find((existing) => existing.phoneNumber === incomingPhone.phoneNumber);
                if (!existingPhone) {
                    incomingPhone.type = 'Addition';
                    incomingPhone.class = 'kt-font-success';
                    output.push(incomingPhone);
                } else if (incomingPhone.phoneType != existingPhone.phoneType) {
                    incomingPhone.type = 'Edit';
                    incomingPhone.class = 'kt-font-primary';
                    output.push(incomingPhone);
                } else if (existingPhone.deleted != incomingPhone.deleted) {
                    incomingPhone.type = p.deleted ? 'Deletion' : 'Addition';
                    incomingPhone.class = p.deleted ? 'kt-font-danger' : 'kt-font-success';
                    output.push(incomingPhone);
                } else if (!existingPhone.deleted) {
                    incomingPhone.type = null;
                    incomingPhone.class = '';
                    output.push(incomingPhone);
                }
            });
            return output;
        },
        guardianEmails() {
            const v = this;
            const change = v.currentChange;
            const output = [];
            if (!change) return output;

            const previousEmails = change.previousStudentGuardian ? change.previousStudentGuardian.emailAddresses : [];
            const newEmails = change.studentGuardian ? change.studentGuardian.emailAddresses : [];
            newEmails.forEach((p) => {
                const incomingEmail = p;
                const existingEmail = previousEmails.find((existing) => existing.emailAddress === incomingEmail.emailAddress);
                if (!existingEmail) {
                    incomingEmail.type = 'Addition';
                    incomingEmail.class = 'kt-font-success';
                    output.push(incomingEmail);
                } else if (incomingEmail.enableSyncGradesHome != existingEmail.enableSyncGradesHome) {
                    incomingEmail.type = 'Edit';
                    incomingEmail.class = 'kt-font-primary';
                    output.push(incomingEmail);
                } else if (existingEmail.deleted != incomingEmail.deleted) {
                    incomingEmail.type = p.deleted ? 'Deletion' : 'Addition';
                    incomingEmail.class = p.deleted ? 'kt-font-danger' : 'kt-font-success';
                    output.push(incomingEmail);
                } else if (!existingEmail.deleted) {
                    incomingEmail.type = null;
                    incomingEmail.class = '';
                    output.push(incomingEmail);
                }
            });
            return output;
        },
        guardianEdits() {
            const v = this;
            const change = v.currentChange;
            if (!change) return [];
            const guardianChanges = [];
            const date = change.dateChanged;
            const relativeDate = moment(date).fromNow();
            const { author } = change;

            if (change.changeType == 'edit') {
                Object.keys(change.previousStudentGuardian).forEach((field) => {
                    if (change.studentGuardian[field] != change.previousStudentGuardian[field]) {
                        guardianChanges.push({
                            field,
                            author,
                            date,
                            relativeDate,
                            type: change.changeType,
                            class: 'primary',
                        });
                    }
                });
            }
            if (change.changeType == 'addition' || change.changeType == 'restore') {
                Object.keys(change.studentGuardian).forEach((field) => {
                    guardianChanges.push({
                        field,
                        author,
                        date,
                        relativeDate,
                        type: change.changeType,
                        class: 'success',
                    });
                });
            }
            if (change.changeType == 'deletion') {
                Object.keys(change.previousStudentGuardian).forEach((field) => {
                    guardianChanges.push({
                        field,
                        author,
                        date,
                        relativeDate,
                        type: change.changeType,
                        class: 'danger',
                    });
                });
            }
            return guardianChanges;
        },
        currentChange() {
            const v = this;
            const changeList = v.filteredChanges || [];
            if (changeList.length == 0) return null;
            return changeList[v.filteredChangeIndex];
        },
    }),
    methods: {
        guardianEdit(field) {
            const v = this;
            return v.guardianEdits.find((e) => e.field == field);
        },
        guardianClass(field) {
            const v = this;
            const edit = v.guardianEdits.find((e) => e.field == field);
            if (!edit) return '';
            return `kt-font-${edit.class}`;
        },
        restoreGuardian() {
            const v = this;
            let guardian;
            if (v.currentChange.changeType == 'edit' || v.currentChange.changeType == 'addition') {
                guardian = deepcopy(v.guardian);
                guardian.emailAddresses = guardian.emailAddresses.filter((e) => e.class !== 'kt-font-danger');
                guardian.phones = guardian.phones.filter((e) => e.class !== 'kt-font-danger');
            }
            if (v.currentChange.changeType == 'deletion') {
                guardian = deepcopy(v.currentChange.previousStudentGuardian);
                guardian.emailAddresses = guardian.emailAddresses.filter((e) => e.class !== 'kt-font-danger');
                guardian.phones = guardian.phones.filter((e) => e.class !== 'kt-font-danger');
            }
            if (guardian) {
                const { schoolTermId, schoolId } = v.user.school;
                const { studentEnrollmentId } = v;
                const { schoolTermEncryptionKey } = this.$store.state.database;

                const guardian = {
                    studentGuardianId: v.guardian.studentGuardianId,
                    guardianTitle: v.guardian.guardianTitle,
                    guardianFirstName: v.guardian.guardianFirstName,
                    guardianLastName: v.guardian.guardianLastName,
                    residesWithStudent: v.guardian.residesWithStudent,
                    relationToStudent: v.guardian.relationToStudent,
                    guardianAuthorization: v.guardian.guardianAuthorization,
                    oralLanguage: v.guardian.oralLanguage,
                    writtenLanguage: v.guardian.writtenLanguage,
                    phones: v.guardian.phones.map((phone) => ({
                        phoneNumber: phone.phoneNumber,
                        phoneType: phone.phoneType,
                        deleted: phone.deleted || false,
                    })),
                    emailAddresses: v.guardian.emailAddresses.map((email) => ({
                        emailAddress: email.emailAddress,
                        enableSyncGradesHome: email.enableSyncGradesHome,
                        deleted: email.deleted || false,
                    })),
                };

                network.guardians.saveStudentGuardians({ url: { schoolId, schoolTermId, studentEnrollmentId }, body: { guardian, schoolTermEncryptionKey } }, (err, resp) => {
                    if (err) {
                        console.error(err);
                        v.saving = false;
                        v.showError('Error saving guardian');
                        return;
                    }
                    v.saving = false;
                    v.editCallback(resp.studentGuardians);
                    v.$refs['student-guardian-change-log-modal'].hide();
                });
            }
        },
        open(studentGuardianId) {
            const v = this;
            v.changes = [];
            v.loading = true;
            v.filteredStudentGuardianId = studentGuardianId;
            v.paginatorIndex = 1;

            const { schoolTermId, schoolId } = v.user.school;
            const { studentEnrollmentId } = v;
            const { schoolTermEncryptionKey } = this.$store.state.database;

            network.guardians.getStudentGuardianChangeLogs({ url: { schoolId, schoolTermId, studentEnrollmentId } }, (err, resp) => {
                if (err) {
                    console.error(err);
                    v.showError(err);
                    return;
                }
                v.changes = resp.changes.map((c) => {
                    let change = c;
                    change.date = moment(change.dateChanged).format('dddd MMMM, Do h:mm:ss A');
                    change.relativeDate = moment(change.dateChanged).fromNow();

                    if (schoolTermEncryptionKey) {
                        const { isEncrypted, encryptedData } = c;
                        if (isEncrypted) {
                            change = decryptObject(change, encryptedData, schoolTermEncryptionKey);
                        }
                    }
                    return change;
                });

                const unique = new Map();
                resp.changes.forEach((c) => {
                    const guardian = c.studentGuardian || c.previousStudentGuardian;
                    unique.set(guardian.studentGuardianId, guardian);
                });
                const guardians = [...unique.values()].map((c) => ({
                    studentGuardianId: c.studentGuardianId,
                    guardianName: `${c.guardianFirstName} ${c.guardianLastName}`,
                }));

                guardians.unshift({
                    studentGuardianId: 0,
                    guardianName: 'Showing All Guardians',
                });
                v.guardians = guardians;
                v.loading = false;
            });
            v.$refs['student-guardian-change-log-modal'].show();
        },
    },
};
