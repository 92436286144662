var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-modal", {
    ref: "student-guardian-change-log-modal",
    attrs: { size: "xl", variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title w-50" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _vm.currentChange
                ? _c("span", { staticClass: "text-muted ml-2" }, [
                    _vm._v(" " + _vm._s(_vm.currentChange.relativeDate)),
                  ])
                : _vm._e(),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _vm.guardian
              ? _c(
                  "form",
                  {
                    staticClass: "kt-portlet__body p-3",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-8 pb-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-elevate alert-light",
                            attrs: { role: "alert" },
                          },
                          [
                            _c("div", { staticClass: "alert-text" }, [
                              _vm._v(
                                " This is a read-only history of changes made via SyncGrades. ATS RCON changes are reflected, but change history for the RCON are not displayed. "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-4 pb-2" }, [
                        _c("div", { staticClass: "row" }, [
                          _vm.currentChange
                            ? _c("div", { staticClass: "col-12" }, [
                                _vm.currentChange.changeType == "deletion"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pull-right kt-badge kt-badge--unified-danger kt-badge--inline kt-font-bold kt-badge--lg kt-badge--rounded w-50",
                                      },
                                      [_vm._v("Deleted Guardian")]
                                    )
                                  : _vm._e(),
                                _vm.currentChange.changeType == "addition"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pull-right kt-badge kt-badge--unified-success kt-badge--inline kt-font-bold kt-badge--lg kt-badge--rounded w-50",
                                      },
                                      [_vm._v("Created Guardian")]
                                    )
                                  : _vm._e(),
                                _vm.currentChange.changeType == "edit"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pull-right kt-badge kt-badge--unified-primary kt-badge--inline kt-font-bold kt-badge--lg kt-badge--rounded w-50",
                                      },
                                      [_vm._v("Edited Guardian")]
                                    )
                                  : _vm._e(),
                                _vm.currentChange.changeType == "restore"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pull-right kt-badge kt-badge--unified-primary kt-badge--inline kt-font-bold kt-badge--lg kt-badge--rounded w-50",
                                      },
                                      [_vm._v("Restored Guardian")]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("guardianFirstName") },
                          [_vm._v("First Name *")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c(
                              "select",
                              {
                                staticClass: "form-control kt-selectpicker",
                                class: _vm.guardianClass("guardianTitle"),
                                on: {
                                  change: function ($event) {
                                    return _vm.guardianTitleChange($event)
                                  },
                                },
                              },
                              _vm._l(_vm.titles, function (title) {
                                return _c(
                                  "option",
                                  {
                                    key: `title_${title}`,
                                    attrs: { disabled: "true" },
                                    domProps: {
                                      value: title,
                                      selected:
                                        title == _vm.guardian.guardianTitle,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(title) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.guardian.guardianFirstName,
                                expression: "guardian.guardianFirstName",
                              },
                            ],
                            ref: "firstName",
                            staticClass: "form-control",
                            class: _vm.guardianClass("guardianFirstName"),
                            attrs: {
                              type: "text",
                              disabled: "true",
                              placeholder: "Guardian First Name",
                              autofocus: "",
                            },
                            domProps: { value: _vm.guardian.guardianFirstName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.guardian,
                                  "guardianFirstName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.guardian.guardianFirstNameError
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.guardian.guardianFirstNameError
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("guardianLastName") },
                          [_vm._v("Last Name *")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-prepend",
                              class: _vm.guardianClass("guardianLastName"),
                            },
                            [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", { staticClass: "la la-user" }),
                              ]),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.guardian.guardianLastName,
                                expression: "guardian.guardianLastName",
                              },
                            ],
                            staticClass: "form-control",
                            class: _vm.guardianClass("guardianLastName"),
                            attrs: {
                              type: "text",
                              disabled: "true",
                              placeholder: "Guardian Last Name",
                            },
                            domProps: { value: _vm.guardian.guardianLastName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.guardian,
                                  "guardianLastName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.guardian.guardianLastNameError
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.guardian.guardianLastNameError) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("guardianAuthorization") },
                          [_vm._v("Authorization *")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-prepend",
                              class: _vm.guardianClass("guardianAuthorization"),
                            },
                            [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "flaticon flaticon-security",
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              class: _vm.guardianClass("guardianAuthorization"),
                              on: {
                                change: function ($event) {
                                  return _vm.authorizationChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.authorizationCodes, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `authorizationCode_${option.code}`,
                                  attrs: { disabled: "true" },
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("relationToStudent") },
                          [_vm._v("Relation To Student")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-prepend",
                              class: _vm.guardianClass("relationToStudent"),
                            },
                            [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", { staticClass: "la la-users" }),
                              ]),
                            ]
                          ),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              class: _vm.guardianClass("relationToStudent"),
                              on: {
                                change: function ($event) {
                                  return _vm.relationChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.relationCodes, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `relation_${option.code}`,
                                  attrs: { disabled: "true" },
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("oralLanguage") },
                          [_vm._v("Oral Language")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-prepend",
                              class: _vm.guardianClass("oralLanguage"),
                            },
                            [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "flaticon2 flaticon2-world",
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              class: _vm.guardianClass("oralLanguage"),
                              on: {
                                change: function ($event) {
                                  return _vm.oralLanguageChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.oralLanguages, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `oralLanguage_${option.code}`,
                                  attrs: { disabled: "true" },
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "label",
                          { class: _vm.guardianClass("writtenLanguage") },
                          [_vm._v("Written Language")]
                        ),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "div",
                            {
                              staticClass: "input-group-prepend",
                              class: _vm.guardianClass("writtenLanguage"),
                            },
                            [
                              _c("span", { staticClass: "input-group-text" }, [
                                _c("i", {
                                  staticClass: "flaticon2 flaticon2-world",
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              class: _vm.guardianClass("writtenLanguage"),
                              on: {
                                change: function ($event) {
                                  return _vm.writtenLanguageChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.writtenLanguages, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `writtenLanguage_${option.code}`,
                                  attrs: { disabled: "true" },
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2 offset-1" }, [
                        _c(
                          "label",
                          {
                            staticClass: "w-100",
                            class: _vm.guardianClass("residesWithStudent"),
                          },
                          [_vm._v("Resides With Student")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--brand",
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.guardian.residesWithStudent,
                                    expression: "guardian.residesWithStudent",
                                  },
                                ],
                                attrs: { disabled: "true", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.guardian.residesWithStudent
                                  )
                                    ? _vm._i(
                                        _vm.guardian.residesWithStudent,
                                        null
                                      ) > -1
                                    : _vm.guardian.residesWithStudent,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.guardian.residesWithStudent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.guardian,
                                            "residesWithStudent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.guardian,
                                            "residesWithStudent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.guardian,
                                        "residesWithStudent",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _c("span"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("p", { staticClass: "kt-font-primary mt-3" }, [
                          _vm._v(" Edited by "),
                          _c("code", [
                            _vm._v(
                              _vm._s(_vm.currentChange.firstName) +
                                " " +
                                _vm._s(_vm.currentChange.lastName)
                            ),
                          ]),
                          _vm._v(
                            " on " +
                              _vm._s(
                                _vm.currentChange ? _vm.currentChange.date : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "pull-right mt-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--danger kt-badge--square",
                            },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" Deletes "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--success kt-badge--square ml-3",
                            },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" Additions "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--primary kt-badge--square ml-3",
                            },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" Edits "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row pt-2" }, [
                      _c("h3", { staticClass: "kt-section__title w-50 ml-0" }, [
                        _vm._v(" Email Accounts "),
                      ]),
                      _c("h3", { staticClass: "kt-section__title w-50" }, [
                        _vm._v(" Phone Numbers "),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          _vm._l(_vm.guardianEmails, function (email, idx) {
                            return _c(
                              "div",
                              { key: `email_${idx}`, staticClass: "row pb-1" },
                              [
                                _c("div", { staticClass: "col-3" }, [
                                  _c("label", { class: email.class }, [
                                    _vm._v("SyncGrades Home"),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-switch kt-switch--outline kt-switch--icon kt-switch--brand",
                                    },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: email.enableSyncGradesHome,
                                              expression:
                                                "email.enableSyncGradesHome",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: "true",
                                            checked: "checked",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              email.enableSyncGradesHome
                                            )
                                              ? _vm._i(
                                                  email.enableSyncGradesHome,
                                                  null
                                                ) > -1
                                              : email.enableSyncGradesHome,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  email.enableSyncGradesHome,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      email,
                                                      "enableSyncGradesHome",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      email,
                                                      "enableSyncGradesHome",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  email,
                                                  "enableSyncGradesHome",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span"),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-9" }, [
                                  _c("label", { class: email.class }, [
                                    _vm._v("Email"),
                                  ]),
                                  _c("div", { staticClass: "input-group" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-group-prepend",
                                        class: email.class,
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "flaticon flaticon-email",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: email.emailAddress,
                                          expression: "email.emailAddress",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: email.class,
                                      attrs: {
                                        type: "email",
                                        disabled: "true",
                                        placeholder: "Email address",
                                      },
                                      domProps: { value: email.emailAddress },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            email,
                                            "emailAddress",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          _vm._l(_vm.guardianPhones, function (phone, idx) {
                            return _c(
                              "div",
                              { key: `phone_${idx}`, staticClass: "row pb-3" },
                              [
                                _c("div", { staticClass: "col-3" }, [
                                  _c("label", { class: phone.class }, [
                                    _vm._v("Type"),
                                  ]),
                                  _c(
                                    "select",
                                    {
                                      staticClass:
                                        "form-control kt-selectpicker",
                                      class: phone.class,
                                      on: {
                                        change: function ($event) {
                                          return _vm.phoneTypeChange(
                                            $event,
                                            idx
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.phoneTypes,
                                      function (phoneType) {
                                        return _c(
                                          "option",
                                          {
                                            key: `phoneType_${idx}_${phoneType}`,
                                            attrs: { disabled: "true" },
                                            domProps: {
                                              value: phoneType,
                                              selected:
                                                phone.phoneType == phoneType,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(phoneType) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "col-9" }, [
                                  _c("label", { class: phone.class }, [
                                    _vm._v("Phone Number"),
                                  ]),
                                  _c("div", { staticClass: "input-group" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-group-prepend",
                                        class: phone.class,
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass: "la la-phone",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      staticClass: "form-control",
                                      class: phone.class,
                                      attrs: {
                                        disabled: "true",
                                        type: "tel",
                                        placeholder: "Telephone number",
                                      },
                                      domProps: {
                                        value: `(${phone.phoneNumber.substring(
                                          0,
                                          3
                                        )}) ${phone.phoneNumber.substring(
                                          3,
                                          6
                                        )}-${phone.phoneNumber.substring(
                                          6,
                                          10
                                        )}`,
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]
                )
              : _c("div", { staticClass: "kt-portlet__body p-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-secondary",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("div", { staticClass: "alert-icon" }, [
                        _c("i", { staticClass: "flaticon-warning" }),
                      ]),
                      _c("div", { staticClass: "alert-text" }, [
                        _vm._v(
                          " This guardian was imported from ATS RCON and has not been edited inside of SyncGrades. "
                        ),
                      ]),
                    ]
                  ),
                ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _vm.guardian
              ? _c("div", { staticClass: "row w-100" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.filteredChanges.length,
                          "per-page": 1,
                        },
                        model: {
                          value: _vm.paginatorIndex,
                          callback: function ($$v) {
                            _vm.paginatorIndex = $$v
                          },
                          expression: "paginatorIndex",
                        },
                      }),
                      _vm.currentChange
                        ? _c("span", { staticClass: "kt-font-primary" }, [
                            _vm._v(
                              "Revision " +
                                _vm._s(_vm.paginatorIndex) +
                                " of " +
                                _vm._s(_vm.filteredChanges.length) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right mt-3",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          disabled: _vm.currentChange.changeType == "restore",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.restoreGuardian()
                          },
                        },
                      },
                      [_vm._v(" Restore This Version ")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }