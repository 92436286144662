<template>
<div :key="`key_${title}_${key}`">
    <StudentSubHeader>
        <template #buttons />
    </StudentSubHeader>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <StudentHeaderPortlet
            v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView"
            :display-wide="true"
        />
        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to view this page.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div class="row">
                <div
                    v-if="loading"
                    class="col-12"
                >
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body">
                            <div class="kt-section kt-section--first">
                                <div class="kt-section__body">
                                    <PortletLoader />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="noData"
                    class="col-12"
                >
                    <div v-if="!encryptionEnabled" class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-section__body">
                            <div class="row">
                                <div class="col-12">
                                    <div
                                        class="alert alert-outline-info m-0"
                                        style="width: 100%"
                                    >
                                        <div class="alert-icon">
                                            <i class="flaticon-warning" />
                                        </div>
                                        <div class="alert-text">
                                            Add a new guardian or upload a RCON from ATS to populate this section for all students
                                        </div>
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-upper"
                                            @click.prevent.stop="addGuardian()"
                                        >
                                            <i
                                                class="fa fa-plus"
                                            />
                                            Add Guardian
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="col-12">
                        <div
                            v-if="canEdit"
                            class="kt-portlet"
                        >
                            <div class="kt-portlet__body p-3">
                                <div class="kt-widget kt-widget--user-profile-3">
                                    <div class="kt-widget__top">
                                        <div class="kt-widget__content pl-3">
                                            <div class="kt-widget__head">
                                                <h3 class="kt-widget__title">
                                                    Student Guardians
                                                </h3>
                                                <div class="kt-widget__action">
                                                    <label
                                                        v-if="isSchoolAdmin"
                                                        class="kt-checkbox kt-checkbox--tick kt-checkbox--danger mr-3"
                                                    >
                                                        <input
                                                            v-model="showDeletes"
                                                            type="checkbox"
                                                        >
                                                        Show deletions and No Access
                                                        <span />
                                                    </label>
                                                    <button
                                                        type="button"
                                                        class="btn btn-brand btn-sm btn-upper ml-3"
                                                        :disabled="!canEdit"
                                                        @click.prevent.stop="addGuardian()"
                                                    >
                                                        <i class="fa fa-plus" />
                                                        Add Guardian
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(guardians, idx) in chunks"
                            :key="`chunk${idx}`"
                            class="row"
                        >
                            <div
                                v-for="(guardian, index) in guardians"
                                :key="`guardian_${guardian.studentGuardianId}_${index}`"
                                class="col-xl-4"
                            >
                                <div class="kt-portlet kt-portlet--height-fluid">
                                    <div class="kt-portlet__head kt-portlet__head--noborder">
                                        <div class="kt-portlet__head-label">
                                            <h3 class="kt-portlet__head-title" />
                                        </div>
                                        <div
                                            v-if="canEdit"
                                            class="kt-portlet__head-toolbar"
                                        >
                                            <a
                                                v-b-tooltip.hover="`Show Changes`"
                                                href="#"
                                                style="margin-right: -20px;"
                                                class="btn btn-icon"
                                                data-toggle="dropdown"
                                                aria-expanded="false"
                                                @click.prevent.stop="changeLogs(guardian.studentGuardianId)"
                                            >
                                                <i class="la  la-clock-o  kt-font-brand" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="kt-portlet__body">
                                        <div class="kt-widget kt-widget--user-profile-2">
                                            <div class="kt-widget__head">
                                                <div class="kt-widget__info pl-0 w-100">
                                                    <a
                                                        class="kt-widget__username"
                                                        :class="{
                                                            'kt-font-danger deleted': guardian.deleted
                                                        }"
                                                    >
                                                        <template v-if="guardian.guardianTitle">
                                                            {{ guardian.guardianTitle }}
                                                        </template>
                                                        {{ guardian.guardianFirstName }} {{ guardian.guardianLastName }}</a>
                                                    <template v-if="guardian.deleted">
                                                        <span class="kt-widget__desc">
                                                            <span
                                                                class="kt-badge kt-badge--danger kt-badge--inline kt-badge--lg kt-badge--rounded"
                                                            >DELETED</span>
                                                        </span>
                                                    </template>
                                                    <template v-else>
                                                        <span
                                                            v-if="guardian.oralLanguage || guardian.writtenLanguage || guardian.relationToStudent"
                                                            class="kt-widget__desc"
                                                        >
                                                            <span :class="'kt-font-' + classForGuardian(guardian)"> {{
                                                                guardian.relationToStudent }}</span>
                                                            <span
                                                                v-if="(guardian.oralLanguage || guardian.writtenLanguage) !== 'English'"
                                                                v-b-tooltip.hover="`Oral lang: ${guardian.oralLanguage || 'N/A'} Written lang: ${guardian.writtenLanguage || 'N/A'}`"
                                                                class="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--unified-dark"
                                                                :class="{
                                                                    'pull-right': guardian.relationToStudent,
                                                                }"
                                                            >
                                                                {{ guardian.oralLanguage || guardian.writtenLanguage ||
                                                                    "N/A" }}
                                                            </span>
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="kt-widget__body">
                                                <div
                                                    class="kt-widget__section"
                                                    style="height: 80px"
                                                >
                                                    <i
                                                        v-if="guardian.residesWithStudent"
                                                        v-b-tooltip.hover="`Resides with student`"
                                                        class="flaticon flaticon-home kt-font-success"
                                                    />
                                                    {{ guardian.guardianAuthorization }}
                                                </div>
                                                <div class="kt-widget__item">
                                                    <div
                                                        v-for="(email, idx) in guardian.emailAddresses.filter((e) => !e.deleted || showDeletes)"
                                                        :key="`email_${email.emailAddress}_${idx}`"
                                                        class="kt-widget__contact"
                                                    >
                                                        <div>
                                                            <span
                                                                :class="{
                                                                    'kt-font-danger': email.deleted,
                                                                    'kt-widget__label': !email.deleted,
                                                                }"
                                                            >Email:</span>
                                                            <a
                                                                href="#"
                                                                :class="{
                                                                    'kt-font-danger': email.deleted,
                                                                    'kt-widget__data': !email.deleted,
                                                                }"
                                                                @click.stop.prevent="copyTextToClipboard(email.emailAddress)"
                                                            >{{
                                                                email.emailAddress }}</a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-for="phone in guardian.phones.filter((p) => !p.deleted || showDeletes)"
                                                        :key="phone.phoneNumber"
                                                        class="kt-widget__contact"
                                                    >
                                                        <div>
                                                            <span
                                                                :class="{
                                                                    'kt-font-danger': phone.deleted,
                                                                    'kt-widget__label': !phone.deleted,
                                                                }"
                                                            >{{ phone.phoneType }}:</span>
                                                            <a
                                                                href="#"
                                                                :class="{
                                                                    'kt-font-danger': phone.deleted,
                                                                    'kt-widget__data': !phone.deleted,
                                                                }"
                                                                @click.stop.prevent="copyTextToClipboard(phone.phoneNumber)"
                                                            >{{
                                                                formatPhone(phone.phoneNumber) }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="canEdit"
                                                class="kt-widget__footer"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-label-primary btn-lg btn-upper"
                                                    :class="'btn-label-' + classForGuardian(guardian)"
                                                    @click="editGuardian(guardian)"
                                                >
                                                    {{ guardian.deleted ? 'Restore Guardian' : 'Edit Guardian' }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </b-overlay>
    </div>
    <StudentGuardianModal
        v-if="lookup && student"
        ref="guardian-modal"
        :lookup="lookup"
        :edit-callback="editCallback"
        :title="student.firstName ? `Guardian for ${student.firstName} ${student.lastName}` : `Guardian for ${student.maskedStudentName}`"
        :student-enrollment-id="studentEnrollmentId * 1"
    />
    <StudentGuardianChangeLogModal
        v-if="lookup && student"
        ref="guardian-change-log-modal"
        :lookup="lookup"
        :edit-callback="editCallback"
        :title="student.firstName ? `Change history for guardian of ${student.firstName} ${student.lastName}` : `Change history for guardian of ${student.maskedStudentName}`"
        :student-enrollment-id="studentEnrollmentId * 1"
    />
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import async from 'async';
import PortletLoader from '../components/PortletLoader.vue';
import userMixins from '../store/mixins/userMixins';
import studentMixins from '../store/mixins/studentMixins';
import studentGuardianMixins from '../store/mixins/studentGuardianMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import StudentGuardianModal from './studentGuardians/StudentGuardianModal.vue';
import StudentGuardianChangeLogModal from './studentGuardians/StudentGuardianChangeLogModal.vue';
import * as network from '../network';
import StudentSubHeader from './StudentSubHeader.vue';
import Types from '../store/Types';

export default {
    name: 'StudentGuardians',
    components: {
        PortletLoader,
        StudentSubHeader,
        StudentGuardianModal,
        StudentGuardianChangeLogModal,
        StudentHeaderPortlet,
    },
    mixins: [
        studentMixins,
        studentGuardianMixins,
        portfolioMixins,
        userMixins,
    ],
    data() {
        return {
            key: 0,
            showDeletes: false,
            noData: true,
            loading: true,
            debounce: null,
            guardians: [],
            lookup: {},
            selectedGuardian: {},
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            student() {
                return this.$_studentMixins_getStudentFromRoute;
            },
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        canEdit() {
            const v = this;
            return ['School Admin', 'Staff', 'Teacher'].includes(v.user.school.role);
        },
        isSchoolAdmin() {
            return this.user.school.role == 'School Admin';
        },
        chunks() {
            const v = this;
            const chunks = [];
            let i = 0;
            const chunkLength = 3;
            const guardians = v.guardians.filter((g) => (v.showDeletes && g.deleted) || !g.deleted);
            const n = guardians.length;
            while (i < n) {
                chunks.push(guardians.slice(i, i += chunkLength));
            }
            if (chunks.length > v.chunkLength) { // spread remainder across others
                const remainder = chunks[chunks.length - 1];
                let x = 0;
                remainder.forEach((item) => {
                    chunks[x].push(item);
                    x += 1;
                    if (x > v.chunkLength - 1) x = 0;
                });
            }
            return chunks;
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
    },
    watch: {
        studentEnrollmentId: {
            handler() {
                this.debouncePopulate();
            },
        },
    },
    mounted() {
        const v = this;
        v.debouncePopulate();
    },
    methods: {
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        debouncePopulate() {
            const v = this;
            clearTimeout(v.debounce);
            v.debounce = setTimeout(() => {
                if (v) { v.populate(); }
            }, 10);
        },
        classForGuardian(guardian) {
            if (guardian.deleted) {
                return 'danger';
            }
            if (guardian.guardianAuthorization.indexOf('Disciplinary Contact') > -1) {
                return 'primary';
            }
            if (guardian.guardianAuthorization.indexOf('Court Order') > -1) {
                return 'danger';
            }
            if (guardian.guardianAuthorization.indexOf('Contact') > -1) {
                return 'success';
            }
            return 'warning';
        },
        editGuardian(guardian) {
            const v = this;
            v.$refs['guardian-modal'].open(guardian);
        },
        addGuardian() {
            const v = this;
            const g = {
                studentGuardianId: '0',
                guardianTitle: 'Mr.',
                guardianFirstName: '',
                guardianLastName: '',
                residesWithStudent: true,
                relationToStudent: 'Father',
                guardianAuthorization: 'Primary Disciplinary Contact with Emergency Contact, Student Pick Up & School Mailings',
                oralLanguage: 'English',
                writtenLanguage: 'English',
                restricted: false,
                etlSourced: false,
                phones: [],
                emailAddresses: [],
            };
            v.$refs['guardian-modal'].open(g);
        },
        changeLogs(studentGuardianId) {
            const v = this;
            v.$refs['guardian-change-log-modal'].open(studentGuardianId);
        },
        formatPhone(number) {
            return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
        },
        applyGuardians(guardians) {
            const v = this;
            v.noData = true;
            v.loading = true;
            // custom sort
            const items = guardians.map((guardian) => {
                const g = guardian;
                g.sort = 4;
                if (g.deleted) {
                    g.sort = 5;
                } else {
                    if (g.guardianAuthorization.indexOf('Primary') == 0) g.sort = 1;
                    if (g.guardianAuthorization.indexOf('Disciplinary') == 0) g.sort = 2;
                    if (g.guardianAuthorization.indexOf('Emergency') == 0) g.sort = 3;
                }
                return g;
            });
            items.sort((a, b) => {
                if (a.sort < b.sort) return -1;
                if (a.sort > b.sort) return 1;
                return 0;
            });

            v.loading = false;
            v.noData = items.length == 0;
            v.guardians = items;
        },
        editCallback(guardians) {
            const v = this;
            v.applyGuardians(guardians.slice());
        },
        populate() {
            const v = this;
            const { studentEnrollmentId } = v;
            if (this.encryptionEnabled) return;
            if (!this.databaseIsReady) return;
            v.loading = true;

            v.$store.dispatch('startSpinner');
            const key = `${studentEnrollmentId}`;

            const { schoolTermId, schoolId } = v.user.school;
            async.auto({
                // guardians(next) {
                //     network.guardians.getStudentGuardians({ url: { schoolId, schoolTermId, studentEnrollmentId } }, next);
                // },
                lookup(next) {
                    network.storage.getAtsLookups({ url: { schoolId, schoolTermId } }, next);
                },
            }, 5, (err, results) => {
                if (err) return alert('Error loading guardians');
                const guardians = this.$_studentGuardianMixins_getStudentGuardians({ studentEnrollmentId });
                v.applyGuardians(guardians);
                v.lookup = results.lookup.lookups;
                if (v.guardians.length == 0 || v.lookup.length == 0) {
                    v.noData = true;
                    v.$store.dispatch('stopSpinner');
                    v.loading = false;
                    return;
                }

                v.noData = false;
                v.$store.dispatch('stopSpinner');
                v.loading = false;
            });
        },
    },
};
</script>

<style scoped>
.kt-widget__contact {
    max-width: 100%;
    overflow: hidden;
}

.kt-widget__data {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 20px;
}
</style>
